const brand = {
    blue: "#28a2d5",
    light: "#f0f0f0",
    gray: "#999999",
    dark: "#666666",
    white: "#ffffff",
    black: "#000000",
}

export default brand
