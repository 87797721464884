import React from "react"

import { StyleSheet, css } from "aphrodite/no-important"
import { Flex, Box } from "grid-styled"

import brand from "../brand"

const ImageSection = ({ background, children = null, ...props }) => {
    return (
        <div className={css(styles.footer)} {...props}>
            <Flex wrap={true} justify="center">
                <Box className={css(styles.text, styles.company)}>
                    Counted LLC
                </Box>
                <Box className={css(styles.sep, styles.responsiveSep)}>|</Box>
                <Box className={css(styles.text, styles.address)}>
                    Contact:{" "}
                    <a href="mailto:info@counted.com">info@counted.com</a>
                </Box>
            </Flex>
            <Flex justify="center">
                <Box className={css(styles.text, styles.copyright)}>
                    &copy; 2019 Counted LLC. All Rights Reserved.
                </Box>
            </Flex>
        </div>
    )
}

const styles = StyleSheet.create({
    footer: {
        paddingTop: 30,
        backgroundColor: brand.black,
        minHeight: 200,
        textAlign: "center",
    },
    text: {
        fontSize: "13px",
        lineHeight: "22px",
        color: brand.white,
    },
    company: {},
    address: { color: brand.blue },
    sep: {
        color: brand.white,
        fontSize: "18px",
        fontWeight: 200,
        paddingLeft: 15,
        paddingRight: 15,
    },
    responsiveSep: {
        "@media (max-width: 450px)": {
            display: "none",
        },
    },
    copyright: {
        fontSize: "10px",
        color: brand.dark,
        paddingTop: 30,
    },
})

export default ImageSection
