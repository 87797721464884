import React from "react"

import { StyleSheet, css } from "aphrodite/no-important"

import brand from "../brand"

const CircleImage = ({
    image,
    label,
    width = 200,
    className = null,
    style = {},
}) => (
    <div
        className={css(styles.circle) + " " + className}
        style={{
            width: width,
            height: width,
            top: -width / 2,
            ...style,
            lineHeight: width + "px",
        }}
    >
        <img src={image} alt={label} width={width} />
    </div>
)

const PersonProfile = ({
    image,
    name = "Profile",
    title = "Founder",
    width = 220,
    height = 380,
    ...props
}) => {
    const { children } = props
    const margin = 60
    const imageWidth = width - margin * 2
    const style = {
        width: width,
        height: height,
    }
    const bioHeight = height - imageWidth - 45
    return (
        <div style={style} className={css(styles.profile)}>
            <CircleImage
                image={image}
                width={imageWidth}
                label={name}
                className={css(styles.image)}
            />
            <div style={{ paddingTop: imageWidth / 2 }} />
            <div className={css(styles.name)}>{name}</div>
            <div className={css(styles.title)}>{title}</div>
            <div className={css(styles.bio)} style={{ height: bioHeight }}>
                {children}
            </div>
        </div>
    )
}
const styles = StyleSheet.create({
    profile: {
        textAlign: "center",
        position: "relative",
        backgroundColor: brand.white,
        boxShadow: "0px 0px 12px -4px rgba(0,0,0,0.4)",
        borderRadius: 3,
        marginTop: 45,
    },

    image: {
        position: "absolute",
    },

    name: {
        textAlign: "center",
        fontWeight: "bold",
        paddingTop: 30,
        textTransform: "uppercase",
        fontSize: "13px",
    },

    title: {
        textAlign: "center",
        paddingTop: 5,
        color: brand.gray,
        fontSize: "12px",
    },

    bio: {
        paddingTop: 15,
        paddingLeft: 15,
        paddingRight: 15,
        color: brand.dark,
        fontSize: "13px",
        textAlign: "left",
        lineHeight: "17px",
        overflow: "scroll",
    },

    circle: {
        border: "7px solid rgb(220, 220, 220)",
        "border ": "7px solid rgba(220, 220, 220, 0.6)",
        margin: "0 auto",
        left: 0,
        right: 0,
        borderRadius: "100%",
        overflow: "hidden",
        backgroundClip: "padding-box",
        backgroundColor: brand.white,
    },
})

export default PersonProfile
