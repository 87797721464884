import React from "react"

import { StyleSheet, css } from "aphrodite/no-important"
import { Flex, Box } from "grid-styled"

import Button from "../Button"
import PartnerLogo from "../PartnerLogo"
import PersonProfile from "../PersonProfile"
import Footer from "../Footer"
import Section from "../Section"
import Quote from "../Quote"

import imgHeader from "./images/header.jpg"
import imgLogo from "./images/counted-logo-tagline-r.svg"
import imgBetterWay from "./images/a-better-way.svg"

// Partner Logos
import imgATTFoundry from "./images/att-foundry-logo.png"
import imgMakeSaltLake from "./images/make-salt-lake-logo.jpg"
import imgFlexpoint from "./images/flexpoint-logo.png"

// Founder Logos
import imgMikeIsom from "./images/profile-mike-isom.jpg"
import imgSteveBarth from "./images/profile-steve-barth.jpg"
import imgTerryPitts from "./images/profile-terry-pitts.jpg"
import imgDuaneJohnson from "./images/profile-duane-johnson.jpg"
import imgLanceSquire from "./images/profile-lance-squires.jpg"

import brand from "../brand"

class Home extends React.Component {
    render() {
        return (
            <div>
                {this.renderHeader()}
                {this.renderWhatIfSection()}
                {this.renderWhatWeDoSection()}
                {this.renderBryanNicholsQuoteSection()}
                {this.renderHowItWorksSection()}
                {this.renderWhoItHelpsSection()}
                {this.renderTrustedPartnersSection()}
                {this.renderWhoWeAreSection()}
                <Footer id="footer" />
            </div>
        )
    }

    renderHeader() {
        return (
            <div className={css(styles.header)}>
                <img
                    className={css(styles.headerImage)}
                    src={imgHeader}
                    alt="Counted Header"
                />
                <Flex
                    className={css(styles.headerStack)}
                    justify="space-around"
                    direction="column"
                    align="center"
                >
                    <Box className={css(styles.logoContainer)}>
                        <img
                            className={css(styles.logoImage)}
                            src={imgLogo}
                            alt="Counted Logo"
                        />
                    </Box>
                    <Box className={css(styles.headerSubtext)}>
                        <img
                            src={imgBetterWay}
                            alt="A better way to track your medication"
                        />
                    </Box>
                    <Box>
                        <Button
                            onClick={() => {
                                window.location = "#footer"
                            }}
                        >
                            <div className={css(styles.headerButton)}>
                                Contact Us
                            </div>
                        </Button>
                    </Box>
                </Flex>
            </div>
        )
    }

    renderWhatIfSection() {
        return (
            <Section color="white">
                <div className={css(styles.narrowSection)}>
                    <h1 className={css(styles.title)}>What If</h1>
                    <div
                        className={css(
                            styles.whatIfPara,
                            styles.whatIfParaFirst
                        )}
                    >
                        your doctor knew which pills you'd taken after heart
                        surgery, from hospital, to recovery center, to home?
                    </div>
                    <h2 className={css(styles.whatIfTitle)}>What If...</h2>
                    <div className={css(styles.whatIfPara)}>
                        nurses could eliminate hours of filling out charts and
                        dedicate more time to patient care?
                    </div>

                    <h2 className={css(styles.whatIfTitle)}>What If...</h2>
                    <div className={css(styles.whatIfPara)}>
                        your pharmacist could proactively help refill
                        prescriptions, before you run out?
                    </div>
                    <h2 className={css(styles.whatIfTitle)}>What If...</h2>
                    <div className={css(styles.whatIfPara)}>
                        insurance companies could identify unused prescriptions,
                        or correlate expensive procedures with medication
                        misuse?
                    </div>
                </div>
            </Section>
        )
    }

    renderWhatWeDoSection() {
        return (
            <Section color="gray">
                <div className={css(styles.narrowSection)}>
                    <h1 className={css(styles.title)}>What We Do</h1>
                    <div className={css(styles.para)}>
                        Counted facilitates medication management cooperation by
                        removing data blind spots between hospitals, nursing
                        facilities, home health, physicians, pharmacies, and
                        insurance companies – giving access to new clinical data
                        with each pill.
                    </div>
                    <div className={css(styles.mediumSpacer)} />
                </div>
            </Section>
        )
    }

    renderBryanNicholsQuoteSection() {
        return (
            <Section color="blue" className={css(styles.paddedSection)}>
                <div className={css(styles.narrowSection)}>
                    <Quote name="Bryan Nichols" title="Registered Pharmacist">
                        With the Counted system I have better control of the
                        inventory, I know what was taken, I know when it was
                        taken, I know who helped administer it.
                    </Quote>
                </div>
            </Section>
        )
    }

    renderHowItWorksSection() {
        return (
            <Section color="white">
                <h1 className={css(styles.title)}>How It Works</h1>
                <div className={css(styles.video)}>
                    <iframe
                        className={css(styles.videoFrame)}
                        src="https://www.youtube.com/embed/EFPqG78Yg7Y?rel=0"
                        frameBorder="0"
                        allowFullScreen
                        title="Counted System"
                    />
                </div>
                <div className={css(styles.mediumSpacer)} />
            </Section>
        )
    }

    renderWhoItHelpsSection() {
        return (
            <Section color="gray">
                <div className={css(styles.narrowSection)}>
                    <h1 className={css(styles.title)}>Who It Helps</h1>
                    <div className={css(styles.para)}>
                        Every year, more than 4 billion prescriptions are filled
                        at American pharmacies and drug stores. But more than
                        half of American patients fail to take those medications
                        as prescribed.
                    </div>
                    <div className={css(styles.para)}>
                        Counted allows individuals and organizations in the
                        medical field to make critical decisions in patient
                        care, insurance claims, as well as production and
                        distribution of certain types of medication based on
                        data that doesn’t currently exist.
                    </div>
                    <h2 className={css(styles.whatIfTitle)}>What If...</h2>
                    <div className={css(styles.whatIfPara)}>
                        drug distributors could re-use medications guaranteed to
                        be unopened?
                    </div>
                    <h2 className={css(styles.whatIfTitle)}>What If...</h2>
                    <div className={css(styles.whatIfPara)}>
                        doctors and law enforcement could identify suspicious
                        medication use patterns and prevent addiction?
                    </div>

                    <div className={css(styles.whatIfFinal)}>
                        <span className={css(styles.whatIfFinalBold)}>
                            What if
                        </span>{" "}
                        this could happen right now, bringing telehealth
                        services to every step of the prescription medication
                        supply chain?
                    </div>
                    <div className={css(styles.mediumSpacer)} />
                </div>
            </Section>
        )
    }

    renderTrustedPartnersSection() {
        return (
            <Section color="white">
                <div className={css(styles.innerBody)}>
                    <h1 className={css(styles.title)}>
                        <span className={css(styles.underlined)}>
                            Trusted Partners
                        </span>
                    </h1>
                    <div className={css(styles.mediumSpacer)} />
                    <Flex justify="center" wrap={true}>
                        <Box className={css(styles.logoBox)}>
                            <PartnerLogo
                                image={imgATTFoundry}
                                desc="AT&T Foundry"
                            />
                        </Box>
                        <Box className={css(styles.logoBox)}>
                            <PartnerLogo
                                image={imgFlexpoint}
                                desc="Flexpoint Sensor Systems"
                            />
                        </Box>
                        <Box className={css(styles.logoBox)}>
                            <PartnerLogo
                                image={imgMakeSaltLake}
                                desc="Make Salt Lake"
                            />
                        </Box>
                        {/* <Box className={css(styles.logoBox)}>
                            <PartnerLogo
                                image={imgUofU}
                                desc="University of Utah"
                            />
                        </Box> */}
                        {/* <Box className={css(styles.logoBox)}>
                            <PartnerLogo
                                image={imgStateOfUtah}
                                desc="The State of Utah"
                            />
                        </Box> */}
                    </Flex>
                </div>
            </Section>
        )
    }

    renderWhoWeAreSection() {
        return (
            <Section color="grayNurse">
                <div
                    className={css(
                        styles.innerBody,
                        styles.profileSectionInnerBody
                    )}
                >
                    <h1 className={css(styles.title)}>
                        <span className={css(styles.underlined)}>
                            Who We Are
                        </span>
                    </h1>
                    <Flex
                        wrap={true}
                        justify="center"
                        className={css(styles.profileSection)}
                    >
                        <Box className={css(styles.profile)}>
                            <PersonProfile
                                name="Mike Isom"
                                title="Founder and CEO"
                                image={imgMikeIsom}
                            >
                                Mike Isom has over 10 years of experience as a
                                Registered Nurse and Clinical Consultant. Mike's
                                main focus has been to bridge the gap between
                                Healthcare IT and Patient Care. He has practiced
                                in various facilities including post-acute and
                                transitional care, mental health, substance
                                abuse, and medication management. His experience
                                with post-acute care and mental health led him
                                to create Counted.
                            </PersonProfile>
                        </Box>
                        <Box className={css(styles.profile)}>
                            <PersonProfile
                                name="Steve Barth"
                                title="COO"
                                image={imgSteveBarth}
                            >
                                Steve Barth has led <em>SB Strategies</em>,
                                providing government, public relations, lobbying
                                and political consulting in the communication,
                                tech, local government, health and non-profit
                                arenas. He has also specialized in small
                                business start-up and regulatory consulting. He
                                was a member of the Utah House of
                                Representatives from 1993 to 1999.
                            </PersonProfile>
                        </Box>
                        <Box className={css(styles.profile)}>
                            <PersonProfile
                                name="Terry Pitts"
                                title="Senior Advisor"
                                image={imgTerryPitts}
                            >
                                Terry A. Pitts has been in the healthcare
                                technology marketplace for over 40 years where
                                he has led, participated, or managed over 30 buy
                                and sell transactions. Terry was a Senior
                                Director of the National Blue Cross and Blue
                                Shield organization in Chicago for several
                                years, served as a CIO with a major healthcare
                                Provider organization (Banner Health).
                            </PersonProfile>
                        </Box>
                        <Box className={css(styles.profile)}>
                            <PersonProfile
                                name="Duane Johnson"
                                title="CTO"
                                image={imgDuaneJohnson}
                            >
                                Duane Johnson founded Make Salt Lake, a
                                non-profit makerspace in Salt Lake City. He has
                                been a software engineer for two decades in the
                                financial and education sectors, including at
                                Instructure and GETCO (now KCG Holdings).
                            </PersonProfile>
                        </Box>
                        <Box className={css(styles.profile)}>
                            <PersonProfile
                                name="Lance Squire"
                                title="CFO"
                                image={imgLanceSquire}
                            >
                                Lance Squire has nearly 20 years of combined
                                management, consulting, operations, marketing,
                                and leadership development experience. Lance has
                                held various executive positions with
                                large-scale nursing home operators in the
                                western US. He has recently owned & operated
                                home health and hospice agencies in Utah and
                                California.
                            </PersonProfile>
                        </Box>
                    </Flex>
                </div>
            </Section>
        )
    }
}

const styles = StyleSheet.create({
    innerBody: {
        "@media (max-width: 1000px)": {
            paddingLeft: 50,
            paddingRight: 50,
        },
    },

    header: {
        position: "relative",
    },

    headerImage: {
        width: "100%",
        marginBottom: "-5px",
    },

    headerStack: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },

    logoContainer: {
        width: "20%",
        minWidth: 140,
    },

    headerSubtext: {
        width: "35%",
        minWidth: 200,
    },

    headerButton: {
        padding: "15px 30px",
        backgroundColor: brand.blue,
        color: brand.white,
        fontSize: "16px",
        borderRadius: "4px",
        "@media (max-width: 600px)": {
            fontSize: "14px",
            padding: "5px 10px",
        },
    },

    title: {
        textAlign: "center",
        color: brand.blue,
        fontWeight: 300,
        margin: 0,
        paddingTop: 30,
    },

    underlined: {
        paddingBottom: 15,
        paddingLeft: 60,
        paddingRight: 60,
        borderBottom: "1px solid #d6d7d9",
    },

    para: {
        textAlign: "center",
        color: brand.dark,
        fontSize: 14,
        paddingTop: 30,
        lineHeight: "18px",
    },

    mediumSpacer: {
        paddingBottom: 30,
    },

    video: {
        marginTop: 30,
        textAlign: "center",
    },

    videoFrame: {
        width: "560px",
        height: "315px",
        "@media (max-width: 660px)": {
            width: "300px",
            height: "169px",
        },
    },

    narrowSection: {
        width: 700,
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: 30,
        "@media (max-width: 660px)": {
            width: "unset",
            margin: 0,
        },
    },

    whatIfTitle: {
        marginTop: 15,
        marginBottom: 10,
        color: brand.blue,
        fontWeight: 500,
        fontSize: "16px",
        textAlign: "center",
    },

    whatIfTitleFirst: {
        fontSize: "28px",
        lineHeight: "36px",
    },

    whatIfPara: {
        margin: 0,
        paddingBottom: 15,
        paddingLeft: 15,
        paddingRight: 15,
        color: brand.dark,
        fontSize: "16px",
        textAlign: "center",
        fontWeight: 300,
    },

    whatIfParaFirst: {
        fontSize: "22px",
        lineHeight: "30px",
    },

    whatIfFinal: {
        marginTop: 15,
        textAlign: "center",
        color: brand.blue,
        fontSize: "20px",
        lineHeight: "28px",
    },

    whatIfFinalBold: {
        color: brand.blue,
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "28px",
    },

    logoBox: {
        padding: "15px 30px",
    },

    profileSection: {
        paddingTop: 60,
        paddingBottom: 30,
    },

    profileSectionInnerBody: {
        backgroundColor: "#e7eeea",
    },

    profile: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 60,
    },

    trustedPartnersSection: {
        paddingBottom: 30,
    },
})

export default Home
