import React from "react"
import ReactDOM from "react-dom"

import "normalize.css"
import "./index.css"
import Home from "./Home"
import { unregister } from './registerServiceWorker';

ReactDOM.render(<Home />, document.getElementById("root"))
unregister();
