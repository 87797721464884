import React from "react"

import { StyleSheet, css } from "aphrodite/no-important"

const PartnerLogo = ({ image, width = 150, desc = "" }) => {
    return (
        <div className={css(styles.profile)}>
            <img src={image} alt={desc} width={width} />
        </div>
    )
}
const styles = StyleSheet.create({
    profile: {
        width: 150,
        textAlign: "center",
    },
})

export default PartnerLogo
