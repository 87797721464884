import React from "react"

import { StyleSheet, css } from "aphrodite/no-important"

import imgQuoteMarks from "./images/marks.png"

const Quote = ({ name, title, children }) => {
    return (
        <div className={css(styles.container)}>
            <div className={css(styles.quote)}>{children}</div>
            <div className={css(styles.name)}>{name}</div>
            <div className={css(styles.title)}>{title}</div>
        </div>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingTop: 30,
        paddingBottom: 30,
    },

    quote: {
        fontSize: "18px",
        background: "no-repeat url(" + imgQuoteMarks + ")",
        backgroundSize: "35px 31px",
        minHeight: 31,
        paddingLeft: 45,
        paddingTop: 15,
        paddingBottom: 15,
    },

    name: {
        textAlign: "right",
        fontSize: "13px",
        lineHeight: "16px",
    },

    title: {
        textAlign: "right",
        fontSize: "12px",
        fontWeight: 200,
        fontStyle: "italic",
        lineHeight: "16px",
    },
})

export default Quote
