import React from "react"

import { StyleSheet, css } from "aphrodite/no-important"

class Button extends React.Component {
    constructor(props) {
        super(props)

        this.handleKeyUp = event => {
            if (
                event.nativeEvent.keyCode === 32 ||
                event.nativeEvent.keyCode === 13
            ) {
                event.preventDefault()
                if (this.props.onClick) {
                    this.props.onClick(event)
                }
            }
        }
    }

    focus() {
        this.buttonRef.focus()
    }

    render() {
        let {
            children,
            className,
            onClick,
            tabIndex,
            ...otherProps
        } = this.props
        return (
            <div
                className={css(styles.button) + " " + className}
                ref={input => {
                    this.buttonRef = input
                }}
                role="button"
                onClick={onClick}
                onKeyUp={this.handleKeyUp}
                tabIndex={tabIndex || 0}
                {...otherProps}
            >
                {children}
            </div>
        )
    }
}

const styles = StyleSheet.create({
    button: {
        cursor: "pointer",
    },
})

export default Button
