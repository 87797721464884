import React from "react"

import { StyleSheet, css } from "aphrodite/no-important"

import brand from "../brand"

const Section = ({ color, className = null, children }) => {
    const colorSheet = colors[color]
    if (!colorSheet) {
        throw new Error("Section can't render with color: " + color)
    }
    return (
        <div
            className={
                css(colorSheet.margins) + (className ? ` ${className}` : "")
            }
        >
            <div className={css(styles.section, colorSheet.background)}>
                {children}
            </div>
        </div>
    )
}

const colors = {
    /* Default "White" color */
    white: StyleSheet.create({
        margins: {
            backgroundColor: brand.light,
        },
        background: {
            backgroundColor: brand.white,
        },
    }),

    /* "Gray" color */
    gray: StyleSheet.create({
        margins: {
            backgroundColor: "#e2e4e5",
        },
        background: {
            backgroundColor: "#f0f1f2",
        },
    }),

    /* "Gray Nurse" color */
    grayNurse: StyleSheet.create({
        margins: {
            backgroundColor: "#dae0dc",
        },
        background: {
            backgroundColor: "#e7eeea",
        },
    }),
    blue: StyleSheet.create({
        margins: {
            backgroundColor: "#269aca",
        },
        background: {
            backgroundColor: "#28a3d6",
            color: brand.white,
        },
    }),
}

const styles = StyleSheet.create({
    /* Default section styles */
    section: {
        width: 800,
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: 80,
        paddingRight: 80,
        "@media (max-width: 1000px)": {
            width: "100%",
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
})

export default Section
